import './style.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faClose, faMessage } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { h, VNode, createRef, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { StyleTransition } from 'preact-transitioning';

import { ChannelList } from './ChannelList';
import { Prompt } from './Prompt';
import { WidgetList } from './WidgetList';
import { SHADOW, isDebug, env } from './constants';
import {
  FontAwesomeSvgIcon,
  IWidgetGroup,
  useConfig,
  IWidgetProps,
  useOnOutsideClick,
  useIsMobile,
} from './lib';
import localStorageCache, { TTL_ONE_DAY } from './lib/local-storage-cache';

const FONT_BASE_URL = isDebug()
  ? '.'
  : env.url + '/storage/v1/object/public/contact_widget';

const Widget = (props: IWidgetProps): VNode | null => {
  const config = useConfig(props, env);
  const {
    groups: widgets,
    loading,
    data_privacy_url,
    welcome_message,
    position,
    background_color: backgroundColor,
    toggle_button_border_radius: toggleButtonBorderRadius,
    content_button_background_color: contentBackgroundColor,
    border_color: borderColor,
    border_radius: borderRadius,
    border_width: borderWidth,
    welcome_title,
    organisation_id,
    font_color: fontColor,
    header_background_color: headerBackgroundColor,
    image_url: imageUrl,
  } = config;

  const showWidgetList = widgets.length > 1;
  const [showWidget, setShowWidget] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState<
    IWidgetGroup | undefined
  >(showWidgetList ? undefined : widgets && widgets[0]);

  useEffect(() => {
    if (!showWidgetList) {
      setSelectedWidget(widgets[0]);
    }
  }, [widgets]);

  const timeoutIdRef = createRef();

  const handleOutside = setShowWidget.bind(this, false);
  useOnOutsideClick(handleOutside);

  // Only show welcome message once per day
  useEffect(() => {
    if (
      // @ts-ignore: Value is set by us on our website to make sure the bubble is always shown in debug
      document.__mateo_widget_force_welcome_message === true ||
      isDebug()
    ) {
      setShowWelcomeMessage(true);
      return;
    }

    let id: number;
    if (!loading) {
      const hasBeenShown = localStorageCache.get<boolean>(
        'welcome-message-shown'
      );
      if (hasBeenShown) return;
      id = setTimeout(() => {
        setShowWelcomeMessage(true);
        localStorageCache.set('welcome-message-shown', true, TTL_ONE_DAY);
      }, 3 * 1000) as unknown as number;
      timeoutIdRef.current = id;
    }
    return () => {
      if (id) clearTimeout(id);
    };
  }, [loading]);

  // We need to store the image in a ref to prevent it from being garbage collected
  const $images = useRef<HTMLImageElement[] | null>(null);

  // Preload images
  useEffect(() => {
    // Respect the user's data connection
    // @ts-ignore: API is experimental, but there is no better way to check for this
    const connection =
      // @ts-ignore
      navigator.connection ||
      // @ts-ignore
      navigator.mozConnection ||
      // @ts-ignore
      navigator.webkitConnection;
    // @ts-ignore
    if (connection?.saveData) {
      return;
    }

    const images = Array.from(
      new Set([
        imageUrl,
        ...widgets.flatMap((widget) =>
          widget.employees.flatMap((employee) => employee.image_url)
        ),
      ])
    ).filter(Boolean) as string[];

    $images.current = images.map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });
  }, [imageUrl, widgets]);

  const [showBubble, setShowBubble] = useState<boolean>(false);

  const fullScreen = useIsMobile();

  if (loading) return null;

  return (
    <Fragment>
      {[100, 200, 300, 500, 700, 800, 900].map((fontWeight) => (
        <Fragment>
          <link
            rel="preload"
            href={`${FONT_BASE_URL}/fonts/inter-v12-latin-${fontWeight}.eot`}
            as="font"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href={`${FONT_BASE_URL}/fonts/inter-v12-latin-${fontWeight}.ttf`}
            as="font"
            crossOrigin="anonymous"
            type="font/truetype"
          />
          <link
            rel="preload"
            href={`${FONT_BASE_URL}/fonts/inter-v12-latin-${fontWeight}.svg`}
            as="font"
            crossOrigin="anonymous"
            type="font/svg"
          />
        </Fragment>
      ))}
      <div
        className={clsx(
          'mateo-pointer-events-none mateo-fixed mateo-bottom-0 mateo-box-border mateo-w-full',
          'mateo-bottom-0',
          position === 'bottom_left' ? 'mateo-left-0' : 'mateo-right-0',
          (showWidget || !showBubble) &&
            fullScreen &&
            'mateo-bottom-0 mateo-left-0 mateo-right-0 mateo-top-0',
          fullScreen ? 'mateo-mx-auto' : 'mateo-mx-3 mateo-max-w-[320px]'
        )}
        style={{
          zIndex: 99999999,
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        }}
        id="mateo-widget"
      >
        <div
          className={clsx(
            'mateo-flex mateo-h-full mateo-w-full mateo-flex-col mateo-justify-end mateo-gap-y-3',
            position === 'bottom_left' ? 'mateo-items-start' : 'mateo-items-end'
          )}
        >
          <StyleTransition
            in={showWidget}
            alwaysMounted
            styles={{
              enter: {
                opacity: 0,
                transform: 'scale(0.8)',
              },
              enterActive: {
                opacity: 1,
                pointerEvents: 'auto',
                transform: 'scale(1)',
              },
              enterDone: {
                opacity: 1,
                pointerEvents: 'auto',
                transform: 'scale(1)',
              },
              exit: {
                opacity: 1,
                transform: 'scale(1)',
              },
              exitActive: {
                transform: 'scale(0.8)',
                opacity: 0,
              },
              exitDone: {
                opacity: 0,
                pointerEvents: 'none',
                transform: 'scale(0.8)',
              },
            }}
            onEnter={() => setShowBubble(false)}
            onExited={() => setShowBubble(true)}
          >
            <div
              className={clsx(
                'mateo-relative mateo-flex mateo-h-full mateo-w-full mateo-flex-col mateo-justify-end mateo-overscroll-contain mateo-will-change-transform',
                position === 'bottom_left'
                  ? 'mateo-origin-bottom-left mateo-items-end'
                  : 'mateo-origin-bottom-right mateo-items-start',
                fullScreen && 'mateo-mx-auto'
              )}
              style={{
                transitionTimingFunction: showWidget
                  ? 'cubic-bezier(0.22, 1, 0.36, 1)'
                  : 'ease-in',
                transitionDuration: showWidget ? '0.4s' : '0.14s',
              }}
            >
              {selectedWidget ? (
                <ChannelList
                  backgroundColor={backgroundColor}
                  contentBackgroundColor={contentBackgroundColor}
                  dataPrivacyUrl={data_privacy_url}
                  organisationId={organisation_id}
                  welcomeTitle={welcome_title}
                  headerBackgroundColor={headerBackgroundColor}
                  borderWidth={borderWidth}
                  goBack={
                    showWidgetList
                      ? () => setSelectedWidget(undefined)
                      : undefined
                  }
                  imageUrl={imageUrl || ''}
                  {...selectedWidget}
                  callToAction={selectedWidget?.call_to_action}
                  borderColor={borderColor}
                  borderRadius={borderRadius}
                  fontColor={fontColor}
                />
              ) : (
                <WidgetList
                  onSelect={setSelectedWidget}
                  widgets={widgets}
                  backgroundColor={backgroundColor}
                  headerBackgroundColor={headerBackgroundColor}
                  contentBackgroundColor={contentBackgroundColor}
                  organisationId={organisation_id}
                  welcomeTitle={welcome_title}
                  imageUrl={imageUrl || ''}
                  fontColor={fontColor}
                  borderWidth={borderWidth}
                  borderColor={borderColor}
                  borderRadius={borderRadius}
                  fullScreen={fullScreen}
                  dataPrivacyUrl={data_privacy_url}
                />
              )}
              {fullScreen && selectedWidget && (
                <button
                  className="mateo-absolute mateo-bottom-3 mateo-right-3 mateo-flex mateo-h-12 mateo-w-12 mateo-cursor-pointer mateo-items-center mateo-justify-center mateo-border-none mateo-bg-transparent"
                  onClick={() => setShowWidget(false)}
                >
                  <FontAwesomeSvgIcon
                    icon={faClose}
                    className="mateo-text-2xl"
                    style={{
                      color: borderColor,
                    }}
                  />
                </button>
              )}
            </div>
          </StyleTransition>
          {/* Bubble */}
          {(!fullScreen || !showWidget) && (fullScreen ? showBubble : true) && (
            <div
              onClick={() => {
                setShowWidget((value) => !value);
                setShowWelcomeMessage(false);
                if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
              }}
              className={clsx(
                'mateo-pointer-events-auto mateo-mb-3 mateo-flex mateo-h-12 mateo-w-12 mateo-cursor-pointer mateo-items-center mateo-justify-center mateo-border-solid',
                fullScreen && 'mateo-mx-3'
              )}
              style={{
                borderWidth,
                backgroundColor,
                borderRadius: toggleButtonBorderRadius,
                borderColor,
                zIndex: 999_999_999,
                boxShadow: SHADOW,
              }}
              id="mateo-widget-bubble"
            >
              <FontAwesomeSvgIcon
                icon={showWidget ? faClose : faMessage}
                aria-hidden="true"
                className="mateo-text-2xl"
                style={{
                  color: borderColor,
                }}
              />
            </div>
          )}
          {/* Prompt */}
          <div className={clsx('mateo-absolute mateo-mb-[80px]')}>
            {Boolean(welcome_message) && welcome_message !== '' && (
              <Prompt
                onClose={() => setShowWelcomeMessage(false)}
                show={!showWidget && !loading && showWelcomeMessage}
                toggleWidget={() => {
                  setShowWidget((value) => !value);
                }}
                imageUrl={imageUrl}
                welcomeMessage={welcome_message}
                backgroundColor={backgroundColor}
                borderRadius={borderRadius}
                borderWidth={borderWidth}
                borderColor={borderColor}
                color={fontColor}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Widget;
