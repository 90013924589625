import clsx from 'clsx';
import { h, JSX } from 'preact';
import { useEffect, useState } from 'preact/hooks';

export function ImageHeaderWithFallback({
  backgroundColor,
  src,
  className,
  ...props
}: Omit<JSX.HTMLAttributes<HTMLImageElement>, 'src'> & {
  src: string;
  backgroundColor: string;
}) {
  const [showImage, setShowImage] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setShowImage(true);
    };

    return () => {
      image.src = '';
      image.onload = null;
    };
  }, [src]);

  if (!showImage) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor,
      }}
      className="mateo-justify-left mateo-flex"
    >
      <img
        alt="Logo"
        className={clsx(
          'mateo-h-12 mateo-w-12 mateo-cursor-pointer mateo-rounded-sm mateo-px-6 mateo-pt-6',
          className
        )}
        src={src}
        {...props}
      />
    </div>
  );
}
