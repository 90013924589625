import { X } from 'lucide-preact';
import { h, FunctionComponent } from 'preact';
import './style.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { StyleTransition } from 'preact-transitioning';

import { SHADOW } from './constants';
import { LazyLoadedImage } from './lib/LazyLoadedImage';

export type PromptProps = {
  show: boolean;
  onClose: () => void;
  toggleWidget: () => void;
  imageUrl?: string | null;
  welcomeMessage?: string | null;
  color?: string | null;
  backgroundColor?: string | null;
  borderRadius: number;
  borderWidth: number;
  borderColor: string;
};

export const Prompt: FunctionComponent<PromptProps> = ({
  show,
  onClose,
  toggleWidget,
  imageUrl,
  welcomeMessage,
  color,
  backgroundColor,
  borderRadius,
  borderWidth,
  borderColor,
}) => {
  return (
    <StyleTransition
      in={show}
      duration={300}
      styles={{
        enter: { opacity: 0 },
        enterActive: { opacity: 1 },
        appear: { opacity: 0 },
        appearActive: { opacity: 1 },
        exit: { opacity: 1 },
        exitActive: { opacity: 0 },
      }}
    >
      <div
        onClick={toggleWidget}
        className="mateo-pointer-events-auto mateo-relative mateo-hidden mateo-min-h-12 mateo-w-80 mateo-rounded-lg mateo-border-solid mateo-p-2 mateo-transition mateo-ease-in sm:mateo-flex"
        style={{
          backgroundColor: backgroundColor || '#ffffff',
          color,
          borderRadius,
          borderWidth,
          borderColor,
          boxShadow: SHADOW,
        }}
        id="mateo-widget-prompt"
      >
        <div className="mateo-flex mateo-flex-row mateo-items-center">
          <div
            id="mateo-close"
            className="mateo-text-s mateo-absolute mateo-right-3 mateo-top-3 mateo-cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
          >
            <X id="mateo-close-icon" size={22} aria-hidden="true" />
          </div>
          {imageUrl && (
            <LazyLoadedImage
              className="mateo-h-12 mateo-w-12 mateo-cursor-pointer mateo-rounded-full mateo-border-solid"
              style={{
                borderRadius: borderRadius * 0.5,
                borderWidth,
                borderColor,
              }}
              src={imageUrl}
              alt="avatar"
              onClick={toggleWidget}
            />
          )}
          <p className="mateo-mx-6 mateo-my-0 mateo-flex mateo-w-full mateo-items-center mateo-truncate mateo-whitespace-pre-wrap mateo-text-sm">
            {welcomeMessage}
          </p>
        </div>
      </div>
    </StyleTransition>
  );
};
