import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { h, FunctionComponent } from 'preact';
import tinyColor from 'tinycolor2';

import './style.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { PrivacyBrandFooter } from './PrivacyBrandFooter';
import { SHADOW } from './constants';
import { FontAwesomeSvgIcon, IWidgetGroup } from './lib';
import { ImageHeaderWithFallback } from './lib/ImageHeaderWithFallback';

export type WidgetListProps = {
  widgets: IWidgetGroup[];
  onSelect: (widget: IWidgetGroup) => void;
  dataPrivacyUrl?: string | null;
  organisationId?: string | null;
  backgroundColor: string;
  headerBackgroundColor: string;
  contentBackgroundColor: string;
  welcomeTitle: string;
  imageUrl: string;
  fontColor: string;
  borderColor: string;
  borderWidth: number;
  borderRadius: number;
  fullScreen: boolean;
};

const CTA_ALPHA = 0.6;

export const WidgetList: FunctionComponent<WidgetListProps> = ({
  onSelect,
  widgets,
  dataPrivacyUrl,
  organisationId,
  backgroundColor = '#fff',
  headerBackgroundColor = '#fff',
  contentBackgroundColor = '#fff',
  welcomeTitle,
  fontColor,
  borderColor,
  borderWidth,
  borderRadius,
  imageUrl,
  fullScreen,
}) => {
  return (
    <div
      className={clsx(
        'mateo-mx-auto mateo-box-border mateo-flex mateo-h-fit mateo-flex-col mateo-overflow-hidden mateo-border-solid mateo-transition-all mateo-duration-300',
        fullScreen ? 'mateo-w-9/10' : 'mateo-w-full'
      )}
      style={{
        borderRadius,
        borderColor,
        borderWidth,
        boxShadow: SHADOW,
      }}
    >
      <div
        className="mateo-scrollbar-hide mateo-flex mateo-h-full mateo-w-full mateo-flex-col mateo-justify-end mateo-overflow-scroll mateo-overscroll-contain"
        id="mateo-widget-widget"
      >
        {imageUrl && (
          <ImageHeaderWithFallback
            backgroundColor={headerBackgroundColor}
            alt="avatar"
            className="mateo-mx-auto mateo-h-20 mateo-w-20 mateo-object-contain"
            src={imageUrl}
          />
        )}
        <div
          style={{
            backgroundColor,
          }}
          className="mateo-flex mateo-h-fit mateo-flex-col mateo-justify-end mateo-gap-y-4 mateo-p-6"
        >
          <h4
            className="mateo-mb-4 mateo-mt-0 mateo-text-sm mateo-font-normal"
            style={{
              color: tinyColor(fontColor).setAlpha(CTA_ALPHA).toHexString(),
            }}
          >
            {welcomeTitle}
          </h4>
          <ul className="mateo-scrollbar-hide mateo-m-0 mateo-flex mateo-max-h-64 mateo-list-none mateo-flex-col mateo-gap-y-2 mateo-overflow-y-scroll mateo-p-0">
            {widgets.map((c, idx) => (
              <li
                key={idx}
                className="mateo-m-0 mateo-box-border mateo-flex mateo-w-full mateo-cursor-pointer mateo-list-none mateo-items-center mateo-justify-between mateo-border mateo-border-solid mateo-border-neutral-200 mateo-p-3.5 mateo-text-sm !mateo-text-neutral-800 hover:mateo-bg-neutral-50 hover:mateo-shadow-sm"
                style={{
                  backgroundColor: contentBackgroundColor,
                  borderColor,
                  borderWidth,
                  borderRadius: borderRadius * 0.5,
                }}
                onClick={() => onSelect(c)}
              >
                <span style={{ color: fontColor }}>{c.name}</span>
                <span style={{ color: fontColor }}>
                  <FontAwesomeSvgIcon
                    icon={faChevronRight}
                    style={{ color: fontColor }}
                    className="mateo-h-10 mateo-w-10"
                  />
                </span>
              </li>
            ))}
          </ul>
          <PrivacyBrandFooter
            dataPrivacyUrl={dataPrivacyUrl}
            organisationId={organisationId}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
    </div>
  );
};
