import './style.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
  faWhatsapp,
  faFacebookMessenger,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faAt, faCommentSms } from '@fortawesome/free-solid-svg-icons';
import { Database } from '@hellomateo/supabase';
import clsx from 'clsx';
import { ChevronLeft } from 'lucide-preact';
import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import tinyColor from 'tinycolor2';

import { EmployeesImages } from './EmployeesImages';
import { PrivacyBrandFooter } from './PrivacyBrandFooter';
import { WhatsappInfo } from './WhatsappInfo';
import { SHADOW } from './constants';
import { FontAwesomeSvgIcon, useIsMobile } from './lib';
import { ImageHeaderWithFallback } from './lib/ImageHeaderWithFallback';
import { LucideIcon } from './lib/LucideIcon';

const SUBTITLE_ALPHA = 0.8;
const CTA_ALPHA = 0.6;

export type ChannelListProps = {
  name?: string | null;
  sms?: string | null;
  email?: string | null;
  whatsapp?: string | null;
  instagram?: string | null;
  facebook?: string | null;
  dataPrivacyUrl?: string | null;
  organisationId?: string | null;
  goBack?: () => void;
  backgroundColor: string;
  contentBackgroundColor: string;
  headerBackgroundColor: string;
  welcomeTitle: string;
  custom_elements: Pick<
    Database['public']['Tables']['web_widget_custom_element']['Row'],
    'id' | 'title' | 'link' | 'icon'
  >[];
  subtitle?: string;
  callToAction?: string;
  imageUrl: string;
  employees: any;
  borderColor: string;
  borderWidth: number;
  fontColor: string;
  borderRadius: number;
};

export const ChannelList: FunctionComponent<ChannelListProps> = ({
  headerBackgroundColor,
  goBack,
  name,
  subtitle,
  email,
  sms,
  whatsapp,
  instagram,
  facebook,
  dataPrivacyUrl,
  organisationId,
  backgroundColor,
  contentBackgroundColor,
  welcomeTitle,
  custom_elements,
  callToAction,
  imageUrl,
  employees,
  borderColor,
  borderWidth,
  fontColor,
  borderRadius,
}) => {
  const [showWhatsApp, setShowWhatsApp] = useState(false);

  const isMobile = useIsMobile();
  const fullScreen = isMobile;

  const elementClasses =
    'mateo-flex mateo-border-solid mateo-gap-x-2 mateo-cursor-pointer mateo-flex-row mateo-items-center mateo-p-2.5 mateo-text-sm hover:mateo-bg-neutral-50 hover:mateo-shadow-sm';
  const iconClasses =
    'mateo-p-1.5 mateo-rounded-full mateo-text-white !mateo-w-4 !mateo-h-4 mateo-overflow-visible';
  const elementStyle = {
    backgroundColor: contentBackgroundColor,
    borderColor,
    borderWidth,
    color: fontColor,
    borderRadius: borderRadius * 0.5,
  };

  return (
    <div
      className={clsx(
        'mateo-box-border mateo-flex mateo-w-full mateo-flex-col mateo-overflow-hidden mateo-border-solid mateo-transition-all mateo-duration-300',
        fullScreen ? 'mateo-h-full' : 'mateo-h-fit'
      )}
      style={{
        borderRadius: fullScreen ? 0 : borderRadius,
        borderColor,
        borderWidth: fullScreen ? 0 : borderWidth,
        boxShadow: SHADOW,
      }}
    >
      <div
        className="mateo-scrollbar-hide mateo-h-full mateo-flex-col mateo-overflow-scroll mateo-overscroll-contain"
        id="mateo-widget-channel"
        style={{
          backgroundColor,
        }}
      >
        {imageUrl && (
          <ImageHeaderWithFallback
            src={imageUrl}
            alt="avatar"
            backgroundColor={headerBackgroundColor}
            className="mateo-mx-auto mateo-h-20 mateo-w-20 mateo-object-contain"
          />
        )}
        <div className="mateo-flex mateo-h-full mateo-flex-col mateo-justify-evenly mateo-gap-y-4 mateo-p-6">
          <div className="mateo-flex mateo-flex-col mateo-gap-y-3">
            <div className="mateo-flex mateo-flex-col mateo-gap-y-1">
              <h5
                className={clsx(
                  'mateo-m-0 mateo-flex mateo-flex-row mateo-items-center mateo-text-base mateo-font-bold'
                )}
                style={{
                  color: fontColor,
                }}
              >
                {showWhatsApp ? 'WhatsApp' : name || welcomeTitle}
              </h5>
              <p
                className="mateo-m-0 mateo-text-sm mateo-font-normal"
                style={{
                  color: tinyColor(fontColor)
                    .setAlpha(SUBTITLE_ALPHA)
                    .toHexString(),
                }}
              >
                {subtitle}
              </p>
            </div>
            <p
              className="mateo-m-0 mateo-text-sm mateo-font-light"
              style={{
                color: tinyColor(fontColor).setAlpha(CTA_ALPHA).toHexString(),
              }}
            >
              {callToAction}
            </p>
          </div>
          <EmployeesImages
            employees={employees}
            borderColor={backgroundColor}
          />
          {showWhatsApp && whatsapp ? (
            <WhatsappInfo
              onBack={() => setShowWhatsApp(false)}
              whatsappLink={whatsapp}
              borderColor={borderColor}
              contentBackgroundColor={contentBackgroundColor}
              fontColor={fontColor}
              borderRadius={borderRadius}
              borderWidth={borderWidth}
            />
          ) : (
            <div className="mateo-flex mateo-flex-col mateo-gap-y-3 mateo-py-2">
              {custom_elements.map((element) => (
                <a href={element.link} target="_blank" rel="noreferrer">
                  <div className={elementClasses} style={elementStyle}>
                    <LucideIcon
                      name={element.icon}
                      className="!mateo-h-4 !mateo-w-4 mateo-overflow-visible mateo-rounded-full mateo-p-1.5"
                      style={{
                        backgroundColor: '#00000010',
                      }}
                    />
                    <span>{element.title || 'Kontaktieren Sie uns'}</span>
                  </div>
                </a>
              ))}
              {whatsapp && (
                <a
                  href={whatsapp}
                  target="_blank"
                  className={elementClasses}
                  style={elementStyle}
                  rel="noreferrer"
                  onClick={(e) => {
                    if (!isMobile) {
                      e.preventDefault();
                      setShowWhatsApp(!showWhatsApp);
                    }
                  }}
                >
                  <FontAwesomeSvgIcon
                    icon={faWhatsapp}
                    aria-hidden="true"
                    className={iconClasses}
                    style={{
                      background:
                        'linear-gradient(180deg, rgba(87,209,99,1) 0%, rgba(35,179,58,1) 100%)',
                      backgroundColor: 'rgb(87,209,99)',
                    }}
                  />
                  <span>WhatsApp</span>
                </a>
              )}
              {facebook && (
                <a
                  className={elementClasses}
                  style={elementStyle}
                  href={facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeSvgIcon
                    icon={faFacebookMessenger}
                    aria-hidden="true"
                    className={iconClasses}
                    style={{
                      background:
                        'linear-gradient(212deg, rgba(0,198,255,1) 0%, rgba(0,104,255,1) 100%)',
                      backgroundColor: 'rgb(0,198,255), ',
                    }}
                  />
                  <span>Facebook Messenger</span>
                </a>
              )}
              {sms && (
                <a
                  className={elementClasses}
                  style={elementStyle}
                  href={sms}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeSvgIcon
                    icon={faCommentSms}
                    aria-hidden="true"
                    className={clsx(iconClasses, 'mateo-bg-brand-500')}
                  />
                  <span>SMS</span>
                </a>
              )}
              {instagram && (
                <a
                  className={elementClasses}
                  style={elementStyle}
                  href={instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeSvgIcon
                    icon={faInstagram}
                    aria-hidden="true"
                    className={iconClasses}
                    style={{
                      background:
                        'radial-gradient(circle at 35% 90%, rgb(254, 197, 100), transparent 50%), radial-gradient(circle at 0px 140%, rgb(254, 197, 100), transparent 50%), radial-gradient(at 0px -25%, rgb(82, 88, 207), transparent 50%), radial-gradient(at 20% -50%, rgb(82, 88, 207), transparent 50%), radial-gradient(at 100% 0px, rgb(137, 61, 194), transparent 50%), radial-gradient(at 60% -20%, rgb(137, 61, 194), transparent 50%), radial-gradient(at 100% 100%, rgb(217, 49, 122), transparent), linear-gradient(rgb(101, 89, 202), rgb(188, 49, 143) 30%, rgb(227, 63, 95) 50%, rgb(247, 118, 56) 70%, rgb(254, 198, 109) 100%)',
                    }}
                  />
                  <span>Instagram</span>
                </a>
              )}
              {email && (
                <a
                  className={elementClasses}
                  style={elementStyle}
                  href={email}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeSvgIcon
                    icon={faAt}
                    aria-hidden="true"
                    className={clsx(iconClasses, 'mateo-bg-info-500')}
                  />
                  <span>E-Mail</span>
                </a>
              )}
            </div>
          )}
          {goBack && (
            <button
              className="mateo-duration-50 mateo-flex mateo-w-min mateo-cursor-pointer mateo-items-center mateo-justify-start mateo-gap-x-1 mateo-rounded-sm mateo-border-none mateo-bg-transparent mateo-decoration-black hover:mateo-underline"
              onClick={goBack}
            >
              <ChevronLeft color={fontColor} />
              <span
                style={{
                  color: fontColor,
                }}
                className="mateo-text-sm"
              >
                Zurück
              </span>
            </button>
          )}
          <PrivacyBrandFooter
            dataPrivacyUrl={dataPrivacyUrl}
            organisationId={organisationId}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
    </div>
  );
};
