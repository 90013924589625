import { h, JSX } from 'preact';
import { useEffect, useState } from 'preact/hooks';

export function LazyLoadedImage({
  src,
  ...props
}: Omit<JSX.HTMLAttributes<HTMLImageElement>, 'src'> & { src: string }) {
  const [showImage, setShowImage] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setShowImage(true);
    };

    return () => {
      image.src = '';
      image.onload = null;
    };
  }, [src]);

  if (!showImage) {
    return null;
  }

  return <img src={src} {...props} />;
}
